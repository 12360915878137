import { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import MailchimpForm from '../newsletter/MailchimpForm';
// import MailchimpForm from '../newsletter/MailchimpForm';

const Header = (): JSX.Element => {
  const [showMailChimpForm, setShowMailchimpForm] = useState(false);

  const activeStyle = { textDecoration: 'underline' };
  return (
    <nav>
      <NavLink
        style={{ textDecoration: 'none', color: 'black' }}
        to="/"
        activeStyle={activeStyle}
        exact>
        Home
      </NavLink>
      {' | '}
      <NavLink
        style={{ textDecoration: 'none', color: 'black' }}
        to="/blog"
        activeStyle={activeStyle}>
        Blog
      </NavLink>
      {' | '}
      <NavLink
        style={{ textDecoration: 'none', color: 'black' }}
        to="/about"
        activeStyle={activeStyle}>
        About
      </NavLink>
      {' | '}
      <NavLink
        style={{ textDecoration: 'none', color: 'black' }}
        to="/contact"
        activeStyle={activeStyle}>
        Contact
      </NavLink>
      {' | '}
      <NavLink
        style={{ textDecoration: 'none', color: 'black' }}
        to={useLocation().pathname}
        onClick={() => {
          setShowMailchimpForm(true);
        }}>
        Subscribe
      </NavLink>

      {showMailChimpForm && (
        <MailchimpForm modalOpen={showMailChimpForm} setModalOpen={setShowMailchimpForm} />
      )}
      {' | '}
       <NavLink
        style={{ textDecoration: 'none', color: 'black' }}
        to="/sponsors-uk"
        activeStyle={activeStyle}>
        Sponsors UK
      </NavLink>
    </nav>
  );
};

export default Header;
