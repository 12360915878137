import { ReactChild, ReactChildren } from 'react';
import cn from 'classnames';
import './Button.css';

export interface LayoutProps {
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
  className?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const Button = ({ children, className, onClick }: LayoutProps): JSX.Element => {
  return (
    <button onClick={onClick} className={cn(className, 'button')}>
      {children}
    </button>
  );
};

export default Button;
