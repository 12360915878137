import { useState } from 'react';
import MailchimpForm from '../newsletter/MailchimpForm';
// import MailchimpFormCustom from '../newsletter/MailchimpFormCustom';
const SubscribeLanding = () => {
  const [showMailChimpForm, setShowMailchimpForm] = useState(false);

  return (
    <>
      <p>
        {`Para recibir la pagina the sponsors UK haz click `}
        <a
          style={{ color: 'black' }}
          href="#"
          onClick={() => {
            setShowMailchimpForm(true);
          }}>
          aquí
        </a>
      </p>
      {showMailChimpForm && (
        <MailchimpForm modalOpen={showMailChimpForm} setModalOpen={setShowMailchimpForm} />
      )}
    </>
  );
};

export default SubscribeLanding;
