import React from 'react';
import './Home.css';
const imageName = 'sapo-de-otro-pozo';

const Home = (): JSX.Element => {
  const [imageSrc, setImageSrc] = React.useState('');

  React.useEffect(() => {
    import(`../../assets/images/${imageName}.jpeg`)
      .then((image) => {
        /* istanbul ignore next */
        setImageSrc(image.default);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className="home-content">
      <div className="home__left-side">
        <img src={imageSrc} alt="sapo de otro pozo"></img>
      </div>
      <div className="home__right-side">
        <h4>English version</h4>
        <p>
          Hello to Sapo de otro Pozo! We are Monika and Marcelo - an expat couple who started our
          youtube journey 2 years ago. We cover topics from life in Norway (where we have been
          living for 5 years) to life in the UK - 12 years in London in England and 1 year in
          Edinburgh in Scotland. We are passionate about nature, culture (we love books and scandi
          crime dramas), climate change, mental health and many more which you can watch on our
          youtube channel. However our passion and many interests we are not able to cover in making
          videos due to simply lack of time to make and edit videos therefore we are super excited
          to share our story more here, on our blog. Consider subscribing to our newsletter in order
          to stay in touch and read news from us. We decided to create this page for people who
          would like to support our Youtube channel and let us know that our work gives value. So if
          you want, you can{' '}
          <a
            target="_blank"
            href="https://www.buymeacoffee.com/sapodeotropozo"
            style={{ textDecoration: 'underline', color: 'black' }}
            rel="noreferrer">
            buy us a coffee!
          </a>{' '}
          😍 Thank you so much! We appreciate each and every one who supports us 🙏{' '}
        </p>
        <h4>Spanish version</h4>
        <p>
          ¡Hola desde Sapo de otro Pozo! Somos Monika y Marcelo, una pareja de expatriados que
          comenzó nuestro viaje de youtube hace 2 años. Cubrimos temas de la vida en Noruega (donde
          hemos estado viviendo durante 5 años) a la vida en el Reino Unido - 12 años en Londres en
          Inglaterra y 1 año en Edimburgo en Escocia. Nos apasiona la naturaleza, la cultura (nos
          encantan los libros y scandi dramas criminales), cambio climático, salud mental y muchos
          más que puede ver en nuestro canal de Youtube. Sin embargo nuestra pasión y muchos
          intereses no somos capaces de cubrir al hacer videos debido simplemente a la falta de
          tiempo para hacer y editar videos, por lo que estamos muy emocionados para compartir más
          nuestra historia aquí, en nuestro blog. Considere suscribirse a nuestro boletín de
          noticias para para estar en contacto y leer nuestras noticias. Decidimos crear esta página
          para las personas que quisieran apoyar nuestro canal de Youtube. y háganos saber que
          nuestro trabajo da valor. Entonces, si quieres, puedes {''}
          <a
            target="_ en blanco"
            href="https://www.buymeacoffee.com/sapodeotropozo"
            style={{ textDecoration: 'underline', color: 'black' }}
            rel="noreferrer">
            ¡Cómpranos un café!
          </a>{' '}
          {''}
          😍 ¡Muchas gracias! Agradecemos a todos y cada uno de los que nos apoyan 🙏 {''}
        </p>
      </div>
    </div>
  );
};

export default Home;
