const ContactPage = (): JSX.Element => (
  <>
    <div>
      <h4>English version</h4>
      <p>
        To stay in touch or let us know your thoughts please do comment in the youtube section. Also
        you can send me an email to <b>sapodeotropozom@gmail.com</b> or contact me through social
        networks.
      </p>
      <p>
        We decided to create this page for people who would like to support our Youtube channel and
        let us know that our work gives value. So if you want, you can{' '}
        <a
          target="_blank"
          href="https://www.buymeacoffee.com/sapodeotropozo"
          style={{ textDecoration: 'underline', color: 'black' }}
          rel="noreferrer">
          Buy us a coffee!
        </a>{' '}
        😍 Thank you so much! We appreciate each and every one who supports us 🙏{' '}
      </p>
    </div>
    <br />
    <div>
      <h4>Spanish version</h4>
      <p>
        Para mantenerse en contacto o hacernos saber lo que piensa, comenta en la sección de
        youtube. También podes enviarnos un correo electrónico a <b> sapodeotropozom@gmail.com </b>{' '}
        o contactarnos a través de redes sociales.
      </p>
      <p>
        Decidimos crear esta página para las personas que quisieran apoyar nuestro canal de Youtube
        y hacernos saber que nuestro trabajo da valor. Entonces, si quieres, puedes comprarnos un{' '}
        <a
          target="_blank"
          href="https://www.buymeacoffee.com/sapodeotropozo"
          style={{ textDecoration: 'underline', color: 'black' }}
          rel="noreferrer">
          Compranos un cafe!
        </a>{' '}
        😍. ¡Muchas gracias! Agradecemos a todos y cada uno de los que nos apoyan 🙏{' '}
      </p>
    </div>
  </>
);

export default ContactPage;
