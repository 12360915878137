/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

// TODO: This need to be converted to a "Controlled Component" (SplitButton or something) and reset when "Reload data" is clicked.

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DropdownTypeRating = ({ onChangeDropdown: onChangeDropdown }: { onChangeDropdown: any }) => {
  const options = [
    'All',
    'Temporary Worker (A (Premium))',
    'Temporary Worker (A (SME+))',
    'Temporary Worker (A rating)',
    'Worker (A (Premium))',
    'Worker (A (SME+))',
    'Worker (A rating)',
    'Worker (Probationary Sponsor)'
  ];

  return (
    <>
      <label htmlFor="select-type-rating">Choose a type:</label>
      <select
        id="select-type-rating"
        onChange={(e) => {
          onChangeDropdown(e.target.value);
        }}>
        {options.map((v) => (
          <option value={v} key={v}>
            {v}
          </option>
        ))}
      </select>
    </>
  );
};

export default DropdownTypeRating;
