import React, { ReactChild, ReactChildren } from 'react';
import './Modal.css';
import ReactDOM from 'react-dom';
import { ReactComponent as CloseCross } from '../../../assets/icons/close.svg';

const Modal = ({
  onClose,
  show,
  children
}: {
  onClose: React.MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
  show: boolean;
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
}): JSX.Element => {
  const showHideClassName = show ? 'modal display-block' : 'modal display-none';

  return ReactDOM.createPortal(
    <div className={showHideClassName} onClick={onClose}>
      <div
        className="modal-main"
        onClick={(e) => {
          e.stopPropagation();
        }}>
        <span className="modal__close" onClick={onClose}>
          <CloseCross fill="black" stroke="gray" />
        </span>
        {children}
      </div>
    </div>,
    document.getElementById('root') as HTMLElement
  );
};

export default Modal;
