const AboutPage = (): JSX.Element => (
  <div>
    <p>
      Marcelo and Monika, a couple who are too curious about life and the world to stay whole life
      in one place. We met in London, instantly fell in love, got married a year later, started
      building a professional careers, bought a house, started a family (we have two wonderful
      boys). We did everything a young couple do and we were really happy about our life.
    </p>
    <blockquote>
      &quot;Life is either a daring adventure or nothing at all.&quot; - Helen Keller
    </blockquote>
    <p>
      But also we were very curious about the world and we dream about white christmas and snowy
      winters to have for our boys a kind of childhood Monika used to have. So we sold our house,
      packed our life in 150 boxes and moved to Norway. We found another beautiful house on a little
      island, fell in love with floor heating, started cross country skiing, ice skating and
      drinking coffee like water. We ate waffles with brunost, pizza Grandiosa and Pølse med lompe
      (if you know, you know). In one word if that is even possible to summarize our experience of
      life in Norway we enjoyed our scandi life.
    </p>
    <blockquote>
      &quot;Character, like a photograph, develops in darkness.&quot; - Yousuf Karsh
    </blockquote>
    <p>
      Living a happy life in one of the best countries in the world gives you many possibilities.
      You can just take as much as you want to do whatever suits you, closing eyes to injustice and
      totalitarianism. Or you can look closer and start to discover the dark sides of Norwegian
      society. We started to question our values and things which matter to us and with pain the
      heart we decided that we don’t want our boys to grow up with Norwegian principles which are
      methodologically taught from an early age. We choose freedom of choice and diversity and once
      again we packed our life in boxes and moved again. Our love for nature and having green
      surroundings within walking distance was a number one priority for us so after some
      brainstorming, researching and job interviews, we landed in Edinburgh - the most green capital
      in Europe.
    </p>
    <blockquote>
      “Here is the world. beautiful and terrible things will happen. don’t be afraid.” - Frederic
      Buechner
    </blockquote>
    <p>
      So here is our short story, although there is not much life essence in short stories. What we
      are trying to say is that we aren&quot;t afraid to live life, to keep trying and exploring our
      possibilities. We call ourselves a global citizen, digital nomads, expats but most of all we
      are a family of four who speak 4 languages at home, love fusion cuisine from all over the
      world, love cycling and visiting museums although our boys nowadays prefer choosing a cafe to
      sit in rather than a gallery to wander around. But we are open and curious about any life
      aspects and we love sharing our inspiration with you all. To stay in touch, consider signing
      up for a newsletter and make sure to subscribe to our channel and let us know what you like.
    </p>
  </div>
);

export default AboutPage;
