/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useState, useEffect } from 'react';

import MailchimpSubscribe from 'react-mailchimp-subscribe';
import Button from '../common/buttons/Button';
import Modal from '../common/modal/Modal';
import './mcFormStyles.scss';

// eslint-disable-next-line react/prop-types
const CustomForm = ({ status, message, onValidated, modalOpen, setModalOpen }) => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
      firstName &&
      email.indexOf('@') > -1 &&
      onValidated({
        EMAIL: email,
        MERGE1: firstName
      });
  };

  useEffect(() => {
    if (status === 'success') clearFields();
    if (modalOpen && status === 'success') clearFields();
  }, [status, modalOpen]);

  const clearFields = () => {
    setFirstName('');
    setEmail('');
  };

  return (
    <form className="mc__form" onSubmit={(e) => handleSubmit(e)}>
      <h3 className="mc__title">
        {status === 'success' ? 'Success!' : 'Join our email list for future updates.'}
      </h3>

      {status === 'sending' && <div className="mc__alert mc__alert--sending">sending...</div>}
      {status === 'error' && (
        <div className="mc__alert mc__alert--error" dangerouslySetInnerHTML={{ __html: message }} />
      )}
      {status === 'success' && (
        <div
          className="mc__alert mc__alert--success"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}

      {status !== 'success' ? (
        <div className="mc__field-container">
          <label htmlFor="email">Email</label>
          <input
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            value={email}
            placeholder="your@email.com"
            required
          />
          <br />
          <label htmlFor="firstName">Name</label>
          <input
            onChange={(e) => setFirstName(e.target.value)}
            type="text"
            value={firstName}
            placeholder="Tu nombre"
            required
          />
        </div>
      ) : null}

      {/*Close button appears if form was successfully sent*/}
      {status === 'success' ? (
        <Button onClick={() => setModalOpen(false)}> Done </Button>
      ) : (
        <input type="submit" value="Subscribe" disabled={false} />
      )}
    </form>
  );
};

const MailchimpForm = (props) => {
  const url = `https://gmail.us20.list-manage.com/subscribe/post?u=${process.env.REACT_APP_CHIMP_USER}&amp;id=${process.env.REACT_APP_CHIMP_ID}`;
  return (
    <Modal
      onClose={() => {
        props.setModalOpen(false);
      }}
      show={true}>
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <CustomForm
            // eslint-disable-next-line react/prop-types
            modalOpen={props.modalOpen}
            // eslint-disable-next-line react/prop-types
            setModalOpen={props.setModalOpen}
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
    </Modal>
  );
};

export default MailchimpForm;
