import { Route, Switch } from 'react-router-dom';
import AboutPage from './components/about/AboutPage';
import ContactPage from './components/contact/ContactPage';

import Header from './components/common/Header';
import PageNotFound from './components/PageNotFound';
import Sponsors from './components/sponsors/SponsorsList';
import './App.css';
import Home from './components/home/Home';
import faceBookIcon from './assets/icons/facebook.svg';
import youtubeIcon from './assets/icons/youtube.svg';
import twitterIcon from './assets/icons/twitter.svg';
import instagramIcon from './assets/icons/instagram.svg';
import pinterestIcon from './assets/icons/pinterest.svg';
import SubscribeLanding from './components/subscribe-landing/SubscribeLanding';

function App(): JSX.Element {
  return (
    <div className="wrapper">
      <header className="header">
        <h1>SAPO DE OTRO POZO</h1>
        <Header />
      </header>

      <article className="content">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/about" component={AboutPage} />
          <Route path="/contact" component={ContactPage} />
          <Route path="/sponsors-uk" component={Sponsors} />
          <Route path="/subscribe-landing" component={SubscribeLanding} />
          <Route
            path="/blog"
            component={() => {
              window.location.replace('https://blog.sapodeotropozo.com');
              return null;
            }}
          />
          <Route component={PageNotFound} />
        </Switch>
      </article>
      <footer className="footer" style={{ flexDirection: 'row' }}>
        <a href="https://www.pinterest.co.uk/sapodeotropozo/" rel="no noreferrer" target="_blank">
          <img src={pinterestIcon} alt="pinterest sapo de otro pozo" />
        </a>
        <a href="https://twitter.com/sapodeotropozom" rel="no noreferrer" target="_blank">
          <img src={twitterIcon} alt="twitter sapo de otro pozo" />
        </a>
        <a href="https://www.instagram.com/sapodeotropozom/" rel="no noreferrer" target="_blank">
          <img src={instagramIcon} alt="instagram sapo de otro pozo" />
        </a>
        <a href="https://www.youtube.com/c/Sapodeotropozom" rel="no noreferrer" target="_blank">
          <img src={youtubeIcon} alt="youtube sapo de otro pozo" />
        </a>
        <a href="https://www.facebook.com/sapodeotropozom" rel="no noreferrer" target="_blank">
          <img src={faceBookIcon} alt="facebook sapo de otro pozo" />
        </a>
      </footer>
    </div>
  );
}

export default App;
